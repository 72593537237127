//big border time

.border-bottom {
    border-bottom: 2px solid black 
}
.border-right {
    border-right: 2px solid black 
}
.border-left {
    border-left: 2px solid black 
}

.border-top {
    border-top: 2px solid black 
}